import { graphql, Link, StaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import React from "react"
import styled from "styled-components"
import Hummelgau from "../images/hummelgau-logo.png"
import Peter from "../images/peter.jpg"
import Sechzig from "../images/unternehmer-sechzig.svg"
import Vater from "../images/vater.jpg"
import { colors, mq } from "../utils/presets"

import ContentBlocker from "../components/ContentBlocker"
import ContentBlockerYoutubeOne from "../components/ContentBlockerYoutubeOne"
import ContentBlockerYoutubeTwo from "../components/ContentBlockerYoutubeTwo"

const FrontPageText = styled.div`

  max-width: 1080px;
  margin: 0 auto;
  padding: 0.8rem;
  margin-top: 5rem;
  text-align: center;
  h2 {
    text-align: left;
  }
  p {
    text-align: left;
  }
  .peter {
    width: 130px;
    margin: 1%;
  }
  ${mq.tablet} {
    .peter {
      width: 200px;
    }
  }
  ${mq.desktop} {
    text-align: left;
    .peter {
      width: 230px;
      float: right;
      margin: 0.5rem;
      margin-right: 0;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
  }
`

const AngebotButton = styled(Link)`
  background: ${colors.green};
  border-radius: 30px;
  display: inline-block;
  margin-top: 2rem;
  padding: 0.5rem 1.5rem 0.6rem;
  color: white !important;
  text-transform: uppercase;
  font-weight: bold;
`

// const GoogleMapsWrapper = styled.div`
//   flex: 0 1 auto;
//   width: 100%;
//   line-height: 0;
//   ${mq.tablet} {
//     width: 100%;
//   }
// `
// const GoogleMapsIframe = styled.div`
//   box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
//   background: ${colors.lightgrey};
//   border: none;
//   margin-bottom: 0;
//   width: 100%;
//   height: 400px;
//   .embed-container {
//     width: 100%;
//     height: 100%;
//   }
//   .embed-container iframe {
//     width: 100%;
//     height: 100%;
//   }
// `

const FlexWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-flow: column;
  .hummelgau {
    width: 150px;
  }
  .sechzig {
    width: 170px;
  }
  .contactflex {
    a {
      text-decoration: none;
      color: ${colors.green};
    }
  }
  ${mq.tablet} {
    margin-top: 7rem;
    flex-flow: row wrap;
    .contactflex {
      padding-left: 5rem;
      order: 2;
    }
    .hummelgau {
      width: 150px;
      margin-right: 2rem;
    }
    .sechzig {
      width: 250px;
    }
  }
`

const FlexLeft = styled.div`
  flex: 1 0 auto;
  width: 100%;
  margin: 0;
  order: 1;
  ${mq.tablet} {
    padding-right: 4rem;
    width: 40%;
  }
`
const FlexRight = styled.div`
  flex: 1 0 auto;
  width: 100%;
  order: 2;
  .hummelgau-video {
    margin-top: 3rem;
    height: 270px;
  }
  ${mq.tablet} {
    width: 60%;
    .hummelgau-video {
      height: 430px;
      margin-top: 0;
    }
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      query FrontPageTextQuery {
        allWordpressWpReferenzen {
          edges {
            node {
              title
              slug
              featured_media {
                localFile {
                  childImageSharp {
                    sizes(maxWidth: 1920) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <FrontPageText>
        <img src={Peter} alt="Peter Hauenstein" className="peter" />
        <img src={Vater} alt="Vater Hauenstein" className="peter" />
        <h2>Ihr Meisterbetrieb seit 1983</h2>
        <p>
          Das Familienunternehmen wurde von Walter Hauenstein 1983 gegründet,
          unter dem Namen Hauenstein Estrichbau. 1985 steigt seine Frau Monika
          Hauenstein in den Betrieb mit ein und ist für das Büro zuständig. Den
          Betrieb, mit Lagerhalle war zuerst in Gsees/Forkendorf angesiedelt.
          1998 errichtete Walter Hauenstein eine Lagerhalle in Hummeltal, das
          Büro war weiterhin in Forkendorf. Peter Hauenstein, sein Sohn, hat
          1999 seinen Gesellenbriefe Estrichleger erworben. Sowie im Anschluss
          seine Meisterprüfung im Bereich Estrich/Belag.
        </p>
        <p>
          Weiterhin erwarb er den Betriebswirt (HWK) und im darauf folgenden
          Jahr den Fliesen und Mosaiklegermeistertitel. Er hat die Firma 2008
          von seinem Vater übernommen und diese in Hauenstein Estriche
          umbenannt. Der Juniorchef hat die Halle in Hummeltal umgebaut und das
          Büro ebenfalls nach Hummeltal verlegt. Weiterhin hat er verschiedene
          Neuigkeiten eingeführt und die Firma vergrößert.
        </p>
        <ContentBlockerYoutubeOne />
        <FlexWrapper>
          <FlexLeft>
            <img src={Sechzig} alt="Sechzig" className="sechzig" />
          </FlexLeft>
          <FlexRight>
            <h2>Den Nachwuchs fördern</h2>
            <p>
              Hauenstein Estriche ist seit 2017 Mitglieder bei Unternehmer für
              Sechzig e. V. Dies ist ein Verein, der sich für die Jugend des
              Fußballvereines 1860 einsetzt und diese fördert. Peter Hauenstein
              ist seit 20 Jahren Mitglied bei dem Fußballverein 1860 und ihn
              verbindet sehr viel mit diesem Verein. Aufgrund dessen setzt er
              sich auch für die Förderung der Jugend des 1860 engagiert ein.
            </p>
          </FlexRight>
        </FlexWrapper>
        <FlexWrapper>
          <FlexLeft>
            <h2>JFG/SG Hummelgau</h2>
            <p>
              Wir als Hauenstein Estrich sind Trikotsponsor von den Kleinen und
              auch bei jedem Turnier vertreten, da es einfach eine
              Herzensangelegenheit ist, den Jungs und Mädels zu zuschauen und zu
              sehen, wie viel Spaß sie an Fußball haben.
            </p>
            <center>
              <OutboundLink
                href="http://www.sc-hummeltal.de/Fussball/JFG-Hummelgau"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Hummelgau}
                  alt="JFG SG Hummelgau"
                  className="hummelgau"
                />
              </OutboundLink>
            </center>
          </FlexLeft>
          <FlexRight>
            <ContentBlockerYoutubeTwo />
          </FlexRight>
        </FlexWrapper>
        <FlexWrapper>
          <FlexRight>
            <ContentBlocker />
          </FlexRight>
          <FlexLeft
            className="contactflex"
            style={{ paddingRight: "0", paddingBottom: "3rem" }}
          >
            <h2>Kontakt</h2>
            <p style={{ fontWeight: "bold" }}>
              Hauenstein Estriche GmbH
              <br /> Am Mailand 9<br /> 95503 Hummeltal
              <br />
              <br />
              Tel:{" "}
              <OutboundLink href="tel:+4992011403">
                +49 (0) 9201 1403
              </OutboundLink>
              <br />
              Fax:{" "}
              <OutboundLink href="tel:+49920179277">
                +49 (0) 9201 792 77
              </OutboundLink>
              <br />
              E-Mail:{" "}
              <OutboundLink href="mailto:estriche@hauenstein-estriche.de">
                estriche@hauenstein-estriche.de
              </OutboundLink>
              <br />
              <AngebotButton to="/kontakt#angebot">
                Angebot einholen
              </AngebotButton>
            </p>
          </FlexLeft>
        </FlexWrapper>
      </FrontPageText>
    )}
  />
)
