import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"

const ReferenzenListe = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  max-width: 1080px;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 2rem;
  li {
    margin: 0.5rem;
    width: 96%;
    position: relative;
    &:hover,
    &:focus {
      box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.25);
      opacity: 0.95;
    }
    ${mq.tablet} {
      width: 44%;
    }
    ${mq.xl} {
      width: 21.5%;
      &:first-child {
        width: calc(21.5% * 4);
        .iimage {
          height: 600px !important;
        }
      }
    }

    flex: 1 0 auto;
    a {
      text-decoration: none;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    .iimage {
      transition: all 0.4s;
      height: 200px !important;
    }
    figcaption {
      padding: 0 0.5rem 0.1rem 0.6rem;
      margin: 0.5rem;
      position: absolute;
      bottom: 0;
      left: -1rem;
      background: ${colors.green};
      color: white;
      font-weight: bold;
      font-size: 0.85rem;
      svg {
        align-self: center;
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      query ReferenzenQuery {
        allWordpressWpReferenzen(
          limit: 5
          sort: { order: DESC, fields: date }
        ) {
          edges {
            node {
              title
              slug
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1060) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <ReferenzenListe>
        {data.allWordpressWpReferenzen.edges.map(referenz => (
          <li key={referenz.node.title}>
            <Link to={`/referenzen/${referenz.node.slug}`}>
              <figure>
                <Img
                  className="iimage"
                  sizes={
                    referenz.node.featured_media.localFile.childImageSharp.fluid
                  }
                />
                <figcaption
                  dangerouslySetInnerHTML={{
                    __html: referenz.node.title,
                  }}
                />
              </figure>
            </Link>
          </li>
        ))}
      </ReferenzenListe>
    )}
  />
)
