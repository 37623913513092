import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { colors, mq } from "../utils/presets"

const LeistungLinks = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  max-width: 1475px;
  justify-content: space-between;
  margin: -8.5rem auto 0;

  .iconimage {
    margin-bottom: 0.6rem;
  }
  h2 {
    font-size: 18px;
    font-family: "Merriweather";
  }
  h2,
  h3 {
    margin: 0;
  }
  h3 {
    margin-top: .2rem;
    font-size: 16px;
    margin-bottom: 1rem;
    font-weight: 300;
  }
  li {
    position: relative;
    width: 100%;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    margin: 1rem;
    text-align: center;
    transition: all 0.2s;
    .faicon {
      position: absolute;
      bottom: 0.5rem;
      right: 0.6rem;
      color: ${colors.green};
      transition: all 0.3s;
    }
    .iconimage {
      height: 60px;
    }
    &:hover,
    &:focus {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
      .faicon {
        right: 0.4rem;
      }
    }
    .Leistung {
      padding: 1rem;
      height: 100%;
    }
    a {
      text-decoration: none;
    }

    p {
      line-height: 1.5;
      font-size: 16px;
      text-align: left;
    }
  }
  ${mq.tablet} {
    margin: -6rem auto 0;
    justify-content: center;
    li {
      width: 46%;
      margin: 1%;
      }
    }
  ${mq.desktop} {
    margin: -3rem auto 0;
    li {
      width: 18%;
      margin: 1%;
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      query LeistungLinksQuery {
        wordpressPage(slug: { regex: "/startseite/" }) {
          title
          slug
          acf {
            leistungen_page {
              icon {
                url {
                  localFile {
                    publicURL
                  }
                }
              }
              title
              subtitle
              kurztext
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <LeistungLinks>
          {data.wordpressPage.acf.leistungen_page.map(item => (
            <li key={item.title}>
              <Link to={`/leistungen#${item.title}`}>
                <div className="Leistung">
                  <img
                    style={{height: "60px", width: "60px"}}
                    className="iconimage"
                    src={item.icon.url.localFile.publicURL}
                    alt={item.title}
                  />
                  <h2>{item.title}</h2>
                  <h3>{item.subtitle}</h3>
                  <p>{item.kurztext}</p>
                  <FontAwesomeIcon
                    style={{height: "20px", width: "20px"}}
                    className="faicon"
                    icon={faArrowRight}
                  />{" "}
                </div>
              </Link>
            </li>
          ))}
        </LeistungLinks>
      </>
    )}
  />
)
