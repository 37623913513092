import React from "react"
import styled from "styled-components"
import { colors } from "../utils/presets"

import YoutubeTwoPreviewImage from "./images/YoutubeTwoPreviewImage"

const ContentBlockerYoutubeTwoWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  picture {
    opacity: 0.5;
  }

  .video-container {
    position: relative;
    /* padding-bottom: 41.8%; */
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .youtubetwotext {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    line-height: 1.5;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    p {
      max-width: 60%;
      font-size: 1rem;
      color: #eee;
      font-weight: bold;
      text-align: center;
      max-width: 400px;
      a {
        font-size: 1.1rem;
        color: white;
        font-weight: bold;
      }
    }
    .ladenButton {
      color: white;
      background: ${colors.green};
      display: inline-block;
      padding: 0.5rem 1rem 0.7rem;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      border: none;
      outline: none;
      font-weight: bold;
      &:hover,
      &:focus {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`

const ContentBlockerYoutubeTwo = () => {
  function handleClick(e) {
    e.preventDefault()

    // Create Element.remove() function if not exist
    if (!("remove" in Element.prototype)) {
      Element.prototype.remove = function() {
        if (this.parentNode) {
          this.parentNode.removeChild(this)
        }
      }
    }

    var youtubetwotext = document.getElementById("youtubetwotext")
    youtubetwotext.remove()
    var div = document.getElementById("youtubetwoframe")
    div.firstElementChild.remove()
    var youtubetwoIframe ='<div class="video-container"><iframe className="hummelgau-video" width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/ORT2pzQraME" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Hummelgau Fußball Video"/></div>';
    div.innerHTML += youtubetwoIframe
  }

  return (
    
      <ContentBlockerYoutubeTwoWrapper id="youtubetwoframe">
          <YoutubeTwoPreviewImage />
          <div id="youtubetwotext" className="youtubetwotext">
            <p>
              Mit dem Laden der Karte akzeptieren Sie die{" "}
              <a
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Datenschutzerklärung
              </a>{" "}
              von YouTube.
            </p>
            <button className="ladenButton" onClick={handleClick}>
              YouTube Video laden
            </button>
          </div>
      </ContentBlockerYoutubeTwoWrapper>
  )
}
export default ContentBlockerYoutubeTwo
