import React from "react"
import styled from "styled-components"
import { colors } from "../utils/presets"

import MapPreviewImage from "../components/images/MapPreviewImage"

const Wrapper = styled.div``

const GoogleMapsWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  picture {
    opacity: 0.7;
  }

  .maptext {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    line-height: 1.5;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    p {
      max-width: 60%;
      font-size: 1rem;
      color: #eee;
      font-weight: bold;
      text-align: center;
      a {
        font-size: 1.1rem;
        color: white;
        font-weight: bold;
      }
    }
    .ladenButton {
      color: white;
      background: ${colors.green};
      display: inline-block;
      padding: 0.5rem 1rem 0.7rem;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      border: none;
      outline: none;
      font-weight: bold;
      &:hover,
      &:focus {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`

const ContentBlocker = ({ type, background, title, text, url }) => {
  function handleClick(e) {
    e.preventDefault()

    // Create Element.remove() function if not exist
    if (!("remove" in Element.prototype)) {
      Element.prototype.remove = function() {
        if (this.parentNode) {
          this.parentNode.removeChild(this)
        }
      }
    }

    var maptext = document.getElementById("maptext")
    maptext.remove()
    var div = document.getElementById("mapsframe")
    div.firstElementChild.remove()
    var gmapiframe =
      "<iframe id='kontakt' title='Google Maps Hauenstein Estriche' width='100%' height='433px' src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2569.8047432912676!2d11.5045463!3d49.902469!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a1bd6c5cb79629%3A0xa16ea717312d8fa5!2sHauenstein+Estriche+Peter+Hauenstein+e.K.!5e0!3m2!1sde!2sde!4v1559668239912!5m2!1sde!2sde' frameborder='0' allowfullscreen />"
    div.innerHTML += gmapiframe
  }

  return (
    <Wrapper>
      <GoogleMapsWrapper id="mapsframe">
        <MapPreviewImage />
        <div id="maptext" className="maptext">
          <p>
            Mit dem Laden der Karte akzeptieren Sie die{" "}
            <a
              href="https://policies.google.com/privacy"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Datenschutzerklärung
            </a>{" "}
            von Google.
          </p>
          <button className="ladenButton" onClick={handleClick}>
            Karte laden
          </button>
        </div>
      </GoogleMapsWrapper>
    </Wrapper>
  )
}
export default ContentBlocker
