import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroImage from "../components/heroimage"
import Referenzen from "../components/Referenzen"
import LeistungLinks from "../components/LeistungLinks"
import FrontPageText from "../components/FrontPageText"
import styled from "styled-components"
import { Link } from "gatsby"
import { mq, colors } from "../utils/presets"

const FrontPagePopUpWrapper = styled.div`
  position: relative;
  height: 0;
  .popup {
    position: absolute;
    display: block;
    left: 1rem;
    top: 2rem;
    background: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    text-decoration: none;
    max-width: 90vw;
    margin: 0 auto;
    transition: all .2s;
    h2 {
      margin: 0;
      
    }
    p {
      margin: 0;
    }
    &:focus,
    &:hover {
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.55);
      opacity: 1;
      margin-top: -.3rem;
      /* background: ${colors.green};
      color: white; */
      h2 {
        color: ${colors.green};
      }
    }
  }
  ${mq.tablet} {
    .popup {
      max-width: 60vw;
      left: 0;
      right: 0;
    }
  }
  ${mq.desktop} {
    max-width: 1475px;
    margin: 0 auto;
    .popup {
      max-width: 22rem;
      left: 1rem;
      right: initial;
      top: 30vh;
    }
  }
  ${mq.xxl} {
    .popup {
      left: 0;
    }
  }
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Startseite"
      keywords={[
        `Hauenstein`,
        `Estriche`,
        `Heizestriche`,
        `Schwimmende Estriche`,
        `Anhydrit-Estriche`,
        `Sanierung`,
        `Unterbodenausgleich`,
        `Bauwerksabdichtungen`,
      ]}
    />
    <FrontPagePopUpWrapper>
      <Link to="/jobs/" className="popup">
        <h2>Stellenausschreibung</h2>
        <p>
          Wir suchen zum nächstmöglichen Zeitpunkt einen{" "}
          <strong>Estrichleger</strong> (m/w/d) in Vollzeit. →
        </p>
      </Link>
    </FrontPagePopUpWrapper>
    <HeroImage style={{ zIndex: -1 }} />
    <h1 style={{ fontSize: "0px", margin: "0", padding: "0", lineHeight: "0" }}>
      Hauenstein Estriche Bayreuth
    </h1>
    <LeistungLinks style={{ zIndex: 999 }} />
    <Referenzen />
    <FrontPageText />
  </Layout>
)

export default IndexPage
