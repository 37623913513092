import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import { mq } from "../utils/presets"

const POST_HERO_QUERY = graphql`
  query HEROQUERY {
    file(relativePath: { regex: "/hero/" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 195px);
  @media screen and (min-width: 480px) {
    height: calc(100vh - 210px);
  }
  ${mq.tablet} {
    height: calc(100vh - 230px);
  }
  ${mq.xl} {
    height: calc(100vh - 225px);
  }
  position: relative;
  overflow: hidden;
`

const Image = styled(Img)`
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const HeroImage = () => (
  <StaticQuery
    query={POST_HERO_QUERY}
    render={data => (
      <ImageWrapper style={{ zIndex: -1 }}>
        <Image
          objectFit="cover"
          objectPosition="50% 50%"
          fluid={data.file.childImageSharp.fluid}
          alt="Carlo"
        />
      </ImageWrapper>
    )}
  />
)

export default HeroImage
